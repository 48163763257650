document.addEventListener('turbolinks:load', () => {
  function fadeOut(el){
    el.style.opacity = 1;

    (function fade() {
      if ((el.style.opacity -= .01) < 0) {
        el.style.display = "none";
      } else {
        requestAnimationFrame(fade);
      }
    })();
  };

  let banner = document.getElementById('flash-banner');
  if (banner) {
    setTimeout(() => fadeOut(banner), 1000);
  }
});
