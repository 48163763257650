import consumer from "./consumer"

export default (element) => {
  const id_elem = document.getElementById(`${element}_id`);
  const spinner = document.getElementById('spinner');

  if (id_elem) {
    consumer.subscriptions.create({
      channel: element == 'slr' ? 'SlrStatusChannel' : 'SvdStatusChannel',
      id: id_elem.getAttribute('data-id')
    }, {
      connected() {
        console.log(`Connected to the ${element} channel!`);
        spinner.classList.remove('hide');

        let deployed = document.getElementById('deployed');
        if (deployed) {
          spinner.classList.add('hide');
        }
      },

      disconnected() {
        console.log('Subscription terminated')
        spinner.classList.add('hide');
      },

      received(data) {
        console.log('Receiving:', data)
        let status = document.getElementById('status');

        if (data.includes('ready')) {
          spinner.classList.add('hide');
          status.innerHTML = "Ready to use!"
        }
        else if (status && !status.blank) {
          spinner.classList.remove('hide');
          status.innerHTML = data;
        }
      },
    })
  }
}
