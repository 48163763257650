import Chart from 'chart.js/auto';

Chart.defaults.color = "#bdcbdb";

document.addEventListener('turbolinks:load', () => {
  var ctx = document.getElementById('drChart').getContext('2d');
  var drChart = new Chart(ctx, {
  type: 'line',
  data: {
      labels: JSON.parse(ctx.canvas.dataset.labels),
      datasets: [
        {
          label: 'Dataset requests',
          data: JSON.parse(ctx.canvas.dataset.data),
        },
        {
          label: 'New dataset requests',
          data: JSON.parse(ctx.canvas.dataset.new_dr),
        },
      ]
    },
  });

  var ctx2 = document.getElementById('customerChart').getContext('2d');
  var customerChart = new Chart(ctx2, {
    type: 'line',
    data: {
        labels: JSON.parse(ctx2.canvas.dataset.labels),
        datasets: [
          {
            label: 'Users',
            data: JSON.parse(ctx2.canvas.dataset.data),
          },
          {
            label: 'New users',
            data: JSON.parse(ctx2.canvas.dataset.new_customers),
          },
        ]
      },
  });

  var ctx3 = document.getElementById('externalCustomerChart').getContext('2d');
  var externalCustomerChart = new Chart(ctx3, {
    type: 'line',
    data: {
        labels: JSON.parse(ctx3.canvas.dataset.labels),
        datasets: [
          {
            label: 'External users',
            data: JSON.parse(ctx3.canvas.dataset.data),
          },
          {
            label: 'New external users',
            data: JSON.parse(ctx3.canvas.dataset.new_external_customers),
          },
        ]
      },
  });
})